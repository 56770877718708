import React from "react";
import "./founder.scss";

//Images
import founder from "../../images/founder.png";
import arrow from "../../images/arrow.svg";
import linkedIn from "../../images/linked-in.svg";

function Founder() {
	return (
		<div className="founder">
			<img src={founder} alt="" />
			<div>
				<h2>Meet Our Founder</h2>
				<p>
					"I am Nic Latouf, your personal brand innovation
					partner. I've founded multiple agencies with two
					successful exits. I co-founded Veldskoen shoes, where I
					crafted a story that brought investment from Mark Cuban
					and Ashton Kutcher. Right now, personal brand is the
					new frontier. Winning businesses have founder-led
					content on LinkedIn. Everyone has a fantastic story to
					tell. Meet with my team to tell your story, build your
					personal brand, and drive opportunities. Let's make you
					unforgettable."
				</p>
				{/* <div className="arrow-container">
					<a
						href="https://www.nic-latouf.com/"
						target="_blank"
						rel="noopener noreferrer"
					>
						View Website
					</a>

					<img src={arrow} alt="" />
				</div> */}
				<a
					href="https://www.linkedin.com/in/nic-latouf/"
					target="_blank"
					rel="noopener noreferrer"
				>
					<img
						src={linkedIn}
						alt="Link to Nic Latouf LinkedIn account"
					/>
				</a>
			</div>
		</div>
	);
}

export default Founder;
