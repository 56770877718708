import React, { useState } from "react";
import "./navbar.scss";

//Images
import logo from "../../images/storilabs-logo.svg";
import burger from "../../images/burger-menu.svg";
import close from "../../images/menu-close.svg";

function Navbar() {
	const [mobileDisplaying, setMobileDisplaying] = useState(false);
	const scrollToSection = (id) => {
		const element = document.getElementById(id);
		if (element) {
			window.scrollTo({
				top: element.offsetTop,
				behavior: "smooth",
			});
		}
	};

	const handleScroll = (id) => (event) => {
		event.preventDefault();
		setMobileDisplaying(!mobileDisplaying);
		scrollToSection(id);
	};
	return (
		<div className="navbar">
			<img className="navbar__logo" src={logo} alt="" />
			<ul className="navbar__desktop">
				<li>
					<a href="#about-us" onClick={handleScroll("about-us")}>
						About Us
					</a>
				</li>
				<li>
					<a href="#why-us" onClick={handleScroll("why-us")}>
						Why Us?
					</a>
				</li>
				<li>
					<a
						href="#contact-us"
						onClick={handleScroll("contact-us")}
					>
						Contact Us
					</a>
				</li>
				<button className="button-light">
					<a
						href="https://calendly.com/daniel-storilabs/30min"
						target="_blank"
						rel="noopener noreferrer"
					>
						Get Started
					</a>
				</button>
			</ul>
			<img
				src={mobileDisplaying ? close : burger}
				alt=""
				onClick={() => {
					setMobileDisplaying(!mobileDisplaying);
				}}
				className="navbar__mobile-menu-icon"
			/>
			{mobileDisplaying ? (
				<ul className="navbar__mobile">
					<li>
						<a
							href="#about-us"
							onClick={handleScroll("about-us")}
						>
							About Us
						</a>
					</li>
					<li>
						<a
							href="#why-us"
							onClick={handleScroll("why-us")}
						>
							Why Us?
						</a>
					</li>
					<li>
						<a
							href="#contact-us"
							onClick={handleScroll("contact-us")}
						>
							Contact Us
						</a>
					</li>
					<button className="button-dark">
						<a
							href="https://calendly.com/daniel-storilabs/30min"
							target="_blank"
							rel="noopener noreferrer"
						>
							Get Started
						</a>
					</button>
				</ul>
			) : null}
		</div>
	);
}

export default Navbar;
