import ContactUs from "../../components/contactUs/contactUs";
import CtaOne from "../../components/ctaOne/ctaOne";
import Elite from "../../components/elite/elite";
import Founder from "../../components/founder/founder";
import Hero from "../../components/hero/hero";
import MagicFormula from "../../components/magicFormula/magicFormula";
import Navbar from "../../components/navbar/navbar";
import Plans from "../../components/plans/plans";
import Reviews from "../../components/reviews/reviews";
import WhyUs from "../../components/whyUs/whyUs";
import "./home.scss";

function Home() {
	return (
		<div className="home">
			<Navbar />
			<Hero />
			<Reviews />
			<CtaOne />
			<MagicFormula />
			<WhyUs />
			<Founder />
			<Elite />
			<Plans />
			<ContactUs />
		</div>
	);
}

export default Home;
