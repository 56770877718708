import React from "react";
import "./ctaOne.scss";

//Images
import magnet from "../../images/become-magnet.svg";

function CtaOne() {
	return (
		<div className="cta-one">
			<div className="cta-one__top">
				<h3>Escape The Sea of Sameness</h3>
				<p>
					Most LinkedIn profiles are as exciting as a soggy
					sandwich. Bland, forgettable, and utterly uninspiring.
					Your profile deserves better. You deserve better.
					Imagine a profile that not only grabs attention but
					demands it. One that showcases your expertise, tells
					your story, and attracts opportunities like a magnet.
				</p>
			</div>
			<div className="cta-one__cta">
				<div className="cta-one__cta__text">
					<h3>Become a Magnet</h3>
					<p>
						Enter StoriLabs, the alchemists of LinkedIn. We
						don't just tweak profiles; we transform them. Our
						secret is a blend of creativity, data-driven
						strategies, and a dash of audacity. We turn the
						mundane into the magnificent, the overlooked into
						the outstanding.
					</p>
				</div>
				<img src={magnet} alt="" />
			</div>
		</div>
	);
}

export default CtaOne;
