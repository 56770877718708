import React, { useState } from "react";
import "./mobilePlans.scss";

//Images

function MobilePlans() {
  const CheckIcon = () => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="11.25" stroke="green" />
      <path
        d="M6 12.75L9.75 16.5L17.25 9"
        stroke="green"
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  const planData = [
    {
      feature: "Profile Audit",
      description:
        "Detailed assessment of your LinkedIn profile with a focus on strengths, weaknesses, and immediate improvement areas.",
      free: true,
      contentOnly: true,
      contentAndEngagement: true,
    },
    {
      feature: "Introductory Content Creation",
      description:
        "Crafting of 2 high-quality posts designed to showcase your expertise and engage your audience",
      free: true,
      contentOnly: true,
      contentAndEngagement: true,
    },
    {
      feature: "Profile Enhancement Guide",
      description:
        "Basic tips and best practices for optimizing your profile for better visibility and engagement.",
      free: true,
      contentOnly: true,
      contentAndEngagement: true,
    },
    {
      feature: "Full Profile Optimization",
      description:
        "Hands-on optimization of your LinkedIn profile, including headline, summary, and experience sections.",
      free: false,
      contentOnly: true,
      contentAndEngagement: true,
    },
    {
      feature: "Content Strategy Development",
      description:
        "Customized content strategy tailored to your goals and target audience.",
      free: false,
      contentOnly: true,
      contentAndEngagement: true,
    },
    {
      feature: "Monthly Content Creation",
      description:
        "Production of 12-15 high-quality posts per month (3-4 per week) to maintain a strong online presence.",
      free: false,
      contentOnly: true,
      contentAndEngagement: true,
    },
    {
      feature: "Scheduled Publishing",
      description:
        "Automated scheduling and publishing of your posts to ensure consistent engagement.",
      free: false,
      contentOnly: true,
      contentAndEngagement: true,
    },
    {
      feature: "Engagement Best Practices",
      description:
        "Personalized guidance on effective engagement tactics to boost your interaction with the network.",
      free: false,
      contentOnly: true,
      contentAndEngagement: true,
    },
    {
      feature: "Monthly Strategy Meetings",
      description: "Monthly meetings to update on strategy and performance.",
      free: false,
      contentOnly: true,
      contentAndEngagement: true,
    },
    {
      feature: "Dedicated Account Manager",
      description: "",
      free: false,
      contentOnly: true,
      contentAndEngagement: true,
    },
    {
      feature: "Video Interviews for Content",
      description: "Monthly video interviews for video snippet content.",
      free: false,
      contentOnly: true,
      contentAndEngagement: true,
    },
    {
      feature: "Daily Network Engagement",
      description:
        "Active engagement with 10-15 relevant posts per weekday to expand your reach and influence.",
      free: false,
      contentOnly: false,
      contentAndEngagement: true,
    },
    {
      feature: "Interactive Comment Management",
      description:
        "Proactive management of comments on your posts to foster meaningful conversations.",
      free: false,
      contentOnly: false,
      contentAndEngagement: true,
    },
    {
      feature: "Ad-hoc Posts",
      description:
        "Ad-hoc posts based on client's current activities (e.g., event attendance).",
      free: false,
      contentOnly: false,
      contentAndEngagement: true,
    },
    {
      feature: "Thought Leadership Development",
      description:
        "Strategic contributions and interactions designed to position you as an industry thought leader.",
      free: false,
      contentOnly: false,
      contentAndEngagement: true,
    },
  ];
  const [selectedContentPlan, setSelectedContentPlan] =
    useState("contentOnlyOne");
  const [contentAmount, setContentAmount] = useState("$2,450");

  const [selectedEngagementPlan, setSelectedEngagementPlan] = useState(
    "contentEngagementOne"
  );
  const [engagementAmount, setEngagementAmount] = useState("$3,450");

  const [mobile, setMobile] = useState("free");

  const handlePlanChange = (event) => {
    const plan = event.target.id;
    setSelectedContentPlan(plan);
    if (plan === "contentOnlyOne") {
      setContentAmount("$2,450");
    } else if (plan === "contentOnlyThree") {
      setContentAmount("$6,450");
    }
  };

  const handlePlanEngagementChange = (event) => {
    const plan = event.target.id;
    setSelectedEngagementPlan(plan);
    if (plan === "contentEngagementOne") {
      setEngagementAmount("$3,450");
    } else if (plan === "contentEngagementThree") {
      setEngagementAmount("$8,950");
    }
  };

  return (
    <div className="mobile-plans">
      <h2>Compare our plans and find yours</h2>
      <div className="mobile-plans__selection">
        <button
          onClick={() => {
            setMobile("free");
          }}
          className={`${mobile === "free" ? "active" : null}`}
        >
          Free
        </button>
        <button
          onClick={() => {
            setMobile("content");
          }}
          className={`${mobile === "content" ? "active" : null}`}
        >
          Content Only
        </button>
        <button
          onClick={() => {
            setMobile("engagement");
          }}
          className={`${mobile === "engagement" ? "active" : null}`}
        >
          Content and Engagement
        </button>
      </div>
      <table>
        <thead>
          <tr>
            <th className={`${mobile !== "free" ? "hide" : null}`} colSpan="2">
              <div className={`mobile-plans__card`}>
                <div>
                  <h4>Free</h4>
                  <h3>
                    $0 <span>/month</span>
                  </h3>

                  <p>
                    A perfect starting point to understand your LinkedIn
                    potential and get a taste of professional branding.
                  </p>
                </div>

                <button className="button-dark">
                  <a
                    href="https://calendly.com/daniel-storilabs/30min"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Get Started
                  </a>
                </button>
              </div>
            </th>
            <th
              className={`${mobile !== "content" ? "hide" : null}`}
              colSpan="2"
            >
              <div className="mobile-plans__card">
                <div>
                  <h4>Content Only</h4>
                  <h3>
                    {contentAmount}{" "}
                    {contentAmount === "$3,950" ? (
                      <span>for 3 months</span>
                    ) : (
                      <span>/month</span>
                    )}
                  </h3>

                  <div className="mobile-plans__card__selection">
                    <div>
                      <input
                        type="checkbox"
                        id="contentOnlyOne"
                        checked={selectedContentPlan === "contentOnlyOne"}
                        onChange={handlePlanChange}
                      />
                      <label htmlFor="contentOnlyOne">Per Month</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        id="contentOnlyThree"
                        checked={selectedContentPlan === "contentOnlyThree"}
                        onChange={handlePlanChange}
                      />
                      <label htmlFor="contentOnlyThree">For 3 Months</label>
                    </div>
                  </div>
                  <p>
                    Elevate your LinkedIn presence with comprehensive support
                    and strategy to drive consistent engagement and growth.
                  </p>
                </div>

                <button className="button-dark">
                  <a
                    href="https://calendly.com/daniel-storilabs/30min"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Get Started
                  </a>
                </button>
              </div>
            </th>
            <th
              className={`${mobile !== "engagement" ? "hide" : null}`}
              colSpan="2"
            >
              <div className="mobile-plans__card">
                <div>
                  <h4>Content and Engagement</h4>
                  <h3>
                    {engagementAmount}{" "}
                    {engagementAmount === "$6,950" ? (
                      <span>for 3 months</span>
                    ) : (
                      <span>/month</span>
                    )}
                  </h3>

                  <div className="mobile-plans__card__selection">
                    <div>
                      <input
                        type="checkbox"
                        id="contentEngagementOne"
                        checked={
                          selectedEngagementPlan === "contentEngagementOne"
                        }
                        onChange={handlePlanEngagementChange}
                      />
                      <label htmlFor="contentEngagementOne">Per Month</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        id="contentEngagementThree"
                        checked={
                          selectedEngagementPlan === "contentEngagementThree"
                        }
                        onChange={handlePlanEngagementChange}
                      />
                      <label htmlFor="contentEngagementThree">
                        For 3 Months
                      </label>
                    </div>
                  </div>
                  <p>
                    Achieve top-tier LinkedIn influence and recognition with our
                    most comprehensive service package, designed for those who
                    aim to lead.
                  </p>
                </div>

                <button className="button-dark">
                  <a
                    href="https://calendly.com/daniel-storilabs/30min"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Get Started
                  </a>
                </button>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {planData.map((plan, index) => (
            <tr key={index}>
              <td>
                <strong>{plan.feature}</strong>
                <p>{plan.description}</p>
              </td>
              <td className={`${mobile !== "free" ? "hide" : null} center`}>
                {plan.free ? <CheckIcon /> : "\u2014"}
              </td>
              <td className={`${mobile !== "content" ? "hide" : null} center`}>
                {plan.contentOnly ? <CheckIcon /> : "\u2014"}
              </td>
              <td
                className={`${mobile !== "engagement" ? "hide" : null} center`}
              >
                {plan.contentAndEngagement ? <CheckIcon /> : "\u2014"}
              </td>
            </tr>
          ))}
          <tr className="no-bg">
            <td
              className={`${mobile !== "free" ? "hide" : null} center`}
              colSpan="2"
            >
              <button className="button-dark">
                <a
                  href="https://calendly.com/daniel-storilabs/30min"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Get Started
                </a>
              </button>
            </td>
            <td
              className={`${mobile !== "content" ? "hide" : null} center`}
              colSpan="2"
            >
              <button className="button-dark">
                <a
                  href="https://calendly.com/daniel-storilabs/30min"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Get Started
                </a>
              </button>
            </td>
            <td
              className={`${mobile !== "engagement" ? "hide" : null} center`}
              colSpan="2"
            >
              <button className="button-dark">
                <a
                  href="https://calendly.com/daniel-storilabs/30min"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Get Started
                </a>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default MobilePlans;
