import React from "react";
import "./elite.scss";

//Images
import elite from "../../images/join-elite.svg";

function Elite() {
	return (
		<div className="elite">
			<div className="elite__cta">
				<div className="elite__cta__text">
					<h3>Join the Elite</h3>
					<p>
						Are you ready to leave the ordinary behind? To
						elevate your LinkedIn presence and unlock doors to
						new opportunities? Then it’s time to take action.
					</p>
					<button className="button-dark">
						<a
							href="https://calendly.com/daniel-storilabs/30min"
							target="_blank"
							rel="noopener noreferrer"
						>
							Get Started
						</a>
					</button>
				</div>
				<img src={elite} alt="" />
			</div>
		</div>
	);
}

export default Elite;
