import React from "react";
import "./whyUs.scss";

//Images

function WhyUs() {
	return (
		<div className="why-us" id="why-us">
			<h2>Why Us?</h2>
			<p>
				Because you’re not here to blend in; you’re here to stand
				out. We’ve dissected the art of LinkedIn success and
				perfected a strategy that works. Our clients don’t just get
				more views; they get more leads, more recognition, and more
				opportunities.
			</p>
		</div>
	);
}

export default WhyUs;
