import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "./reviews.scss";

//Images
import post1 from "../../images/posts/post1.png";
import post3 from "../../images/posts/post3.png";
import post4 from "../../images/posts/post4.png";
import post5 from "../../images/posts/post5.png";

function Reviews() {
	return (
		<div className="reviews">
			<Swiper
				slidesPerView={3}
				spaceBetween={40}
				loop={true} // Enables continuous loop mode
				speed={5000} // Adjust the speed to make the transition smoother
				autoplay={{
					delay: 1000, // Makes the autoplay continuous
					disableOnInteraction: true,
				}}
				modules={[Autoplay]}
				freeMode={true}
				breakpoints={{
					// when window width is >= 320px
					0: {
						slidesPerView: 1,
						spaceBetween: 20,
					},
					// when window width is >= 480px
					1000: {
						slidesPerView: 2,
						spaceBetween: 30,
					},
					// when window width is >= 640px
					1300: {
						slidesPerView: 3,
						spaceBetween: 40,
					},
				}}
			>
				<SwiperSlide>
					<div>
						<img src={post1} alt="" />
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div>
						<img src={post3} alt="" />
					</div>
				</SwiperSlide>

				<SwiperSlide>
					<div>
						<img src={post4} alt="" />
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div>
						<img src={post5} alt="" />
					</div>
				</SwiperSlide>
			</Swiper>
		</div>
	);
}

export default Reviews;
