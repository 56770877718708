import React from "react";
import "./magicFormula.scss";

//Images
import perfection from "../../images/profile-perfection.svg";
import captivates from "../../images/content-that-captivates.svg";
import mastery from "../../images/engagement-mastery.svg";
import brilliance from "../../images/strategic-brilliance.svg";

function MagicFormula() {
	return (
		<div className="magic-formula" id="about-us">
			<h2>Our Magic Formula</h2>
			<div className="magic-formula__grid">
				<div className="magic-formula__grid__item">
					<h4>Profile Perfection:</h4>
					<p>
						We sculpt your LinkedIn profile to reflect your
						true professional brilliance.
					</p>
					<div>1</div>
					<img src={perfection} alt="" />
				</div>
				<div className="magic-formula__grid__item">
					<h4>Content That Captivates:</h4>
					<p>
						Say goodbye to boring posts. We craft content that
						sparks conversations, engages your audience, and
						builds your personal brand.
					</p>
					<div>2</div>
					<img src={captivates} alt="" />
				</div>
				<div className="magic-formula__grid__item">
					<h4>Engagement Mastery:</h4>
					<p>
						We don't just post; we interact. We engage with
						your network, foster meaningful connections, and
						position you as a thought leader.
					</p>
					<div>3</div>
					<img src={mastery} alt="" />
				</div>
				<div className="magic-formula__grid__item">
					<h4>Strategic Brilliance:</h4>
					<p>
						Behind every great profile is a strategy. We
						tailor ours to fit your goals, using insights from
						analyzing thousands of successful posts.
					</p>
					<div>4</div>
					<img src={brilliance} alt="" />
				</div>
			</div>
		</div>
	);
}

export default MagicFormula;
