import React from "react";
import "./hero.scss";

//Images
import hero from "../../images/hero.svg";

function Hero() {
	return (
		<div className="hero">
			<div className="hero__text">
				<h1>Unleash Your LinkedIn Power</h1>
				<p>
					Are you another face in the LinkedIn crowd? Or are you
					ready to stand out, make waves, and turn your profile
					into a beacon of opportunity?
				</p>
				<span>
					Welcome to your LinkedIn transformation journey.
				</span>
				<button className="button-dark">
					<a
						href="https://calendly.com/daniel-storilabs/30min"
						target="_blank"
						rel="noopener noreferrer"
					>
						Get Started
					</a>
				</button>
			</div>
			<img src={hero} alt="" />
		</div>
	);
}

export default Hero;
